import React from 'react'
import { MenuProps, Typography } from 'antd'

const { Text } = Typography

export const configItems: MenuProps['items'] = [
  {
    key: 'conf-1',
    label: 'Новая'
  },
  {
    key: 'conf-2',
    label: 'Открыть'
  },
  {
    key: 'conf-3',
    label: 'Сохранить'
  },
  {
    key: 'conf-4',
    label: 'Сохранить как...'
  },
  {
    key: 'conf-5',
    label: 'Импорт'
  },
  {
    key: 'conf-6',
    label: 'Экспорт'
  },
  {
    key: 'conf-7',
    label: <Text type="danger">Удалить конфигурацию</Text>
  },
]
