import type { Key } from 'react'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'
import type { DirTreeData, Directory } from 'entities/directory'
import type { DirectoryState } from '../types'

const initialState: DirectoryState = {
  directories: null,
  selectedDirectory: null,
  expandedDirs: ['dir-0'],
  dirsList: []
}

export const directorySlice = createSlice({
  name: 'directory',
  initialState,
  reducers: {
    setDirectories: (state, action: PayloadAction<Directory[]>) => {
      state.directories = action.payload
    },
    setSelectedDirectory: (state, action: PayloadAction<DirTreeData | null>) => {
      state.selectedDirectory = action.payload
    },
    setExpandedDirs: (state, action: PayloadAction<Key[]>) => {
      state.expandedDirs = action.payload
    },
    setList: (state, action: PayloadAction<DirTreeData[]>) => {
      state.dirsList = action.payload
    },
  },
})

export const directoryReducer = directorySlice.reducer
export const { setDirectories, setSelectedDirectory, setExpandedDirs, setList } = directorySlice.actions
export const selectDirectories = (state: RootState) => state.directory.directories
export const selectedDirectory = (state: RootState) => state.directory.selectedDirectory
export const selectExpandedDirs = (state: RootState) => state.directory.expandedDirs
export const selectDirs = (state: RootState) => state.directory.dirsList
