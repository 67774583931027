import type { DirTreeData } from 'entities/directory'

type CreateChannelsArrFn = (
  data: DirTreeData | null,
  isRoot: boolean,
  channelsLength: number | undefined) => string[] | null

export const createChannelsArr: CreateChannelsArrFn = (data, isRoot, channelsLength) => {
  if (isRoot) return channelsLength ? [] : null

  function generateUrl(dir: DirTreeData | null) {
    if (dir) {
      let channelIds: string[] = []

      if (!dir.objects?.length && !dir.children?.length)
        return null

      if (dir.objects && dir.objects.length)
        channelIds = channelIds.concat(dir.objects)

      if (dir.children && dir.children.length) {
        dir.children.forEach(item => {
          const ids = generateUrl(item)

          if (ids)
            channelIds = channelIds.concat(ids)
        })
      }

      return channelIds.length ? channelIds : null
    } else
      return []
  }

  return generateUrl(data)
}
