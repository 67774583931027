import type { Module, Plugin, ModulesType, TreeDataType } from '../types'

export const getTreeData = (types: ModulesType[], plugins: Plugin[], modules: Module[]): TreeDataType[] => (
  types.reduce<TreeDataType[]>((typesAcc, typesCurr) => {
    typesAcc.push({
      title: typesCurr.type_name,
      key: `type-${typesCurr.type_index}`,
      children: plugins.reduce<TreeDataType[]>((plugAcc, plugCurr) => {
        if (plugCurr.type_index === typesCurr.type_index)
          plugAcc.push({
            title: plugCurr.name,
            key: `${plugCurr.name}-${plugCurr.type_index}`,
            name: plugCurr.name,
            children: modules.reduce<TreeDataType[]>((modAcc, modCurr) => {
              if (modCurr.plugin_name === plugCurr.name)
                modAcc.push({
                  title: modCurr.name,
                  key: `module-${modCurr.uid}`,
                  pluginName: modCurr.plugin_name,
                })

              return modAcc
            }, [])
          })

        return plugAcc
      }, [])
    })

    return typesAcc
  }, [])
)
