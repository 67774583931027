import React, { useEffect, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'
import cn from 'classnames'
import { useAppDispatch, useAppSelector } from 'app/store'
import { ChannelContent } from 'widgets/channel-content'
import { AddDirectory } from 'features/directory/add-directory'
import { ChangeDirectory } from 'features/directory/change-directory'
import { DeleteDirectory } from 'features/directory/delete-directory'
import {
  createDataTreeArr,
  selectDirs, selectDirectories, selectedDirectory, selectExpandedDirs,
  setExpandedDirs, setList, setSelectedDirectory
} from 'entities/directory'
import type { OnDropType } from 'entities/directory'
import { selectCurrentProject } from 'entities/project'
import { changeDirectoryParent, DIR_URL, getDirectories } from 'shared/api/directories'
import keyToId from 'shared/lib/key-to-id'
import { Directories } from 'shared/ui/directories'
import css from './ChannelsLayout.module.scss'

export const ChannelsLayout: FC = () => {
  const dispatch = useAppDispatch()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const dirs = useAppSelector(selectDirs)
  const directories = useAppSelector(selectDirectories)
  const activeDirectory = useAppSelector(selectedDirectory)
  const expandedKeys = useAppSelector(selectExpandedDirs)
  const currentProject = useAppSelector(selectCurrentProject)
  const { isLoading, isValidating } = useSWRImmutable(DIR_URL, () => getDirectories())
  const { mutate } = useSWRConfig()

  useEffect(() => {
    if (directories && currentProject) {
      // TODO: Должно вызываться только после получения новых данных
      dispatch(setList([{
        title: currentProject,
        key: 'dir-0',
        children: createDataTreeArr(directories)
      }]))
    }
  }, [directories, currentProject])

  useEffect(() => {
    if (directories && activeDirectory) {
      const updated = directories.find(d => d.uid === keyToId(activeDirectory.key))
      const selected = (updated && activeDirectory) ? { ...activeDirectory, objects: updated.objects } : activeDirectory

      dispatch(setSelectedDirectory(selected))
    }
  }, [directories])

  useEffect(() => {
    setShowLoader(isLoading || isValidating)
  }, [isLoading, isValidating])

  const onDropHandler: OnDropType = ({ dragNode, node, dropToGap }) => {
    if (!dropToGap) {
      setShowLoader(true)

      changeDirectoryParent(keyToId(dragNode.key), keyToId(node.key))
        .then(() => mutate(DIR_URL))
        .catch(err => console.error(err))
        .finally(() => setShowLoader(false))
    } else if (node.dir && (dragNode.dir !== node.dir)) {
      setShowLoader(true)

      changeDirectoryParent(keyToId(dragNode.key), node.dir)
        .then(() => mutate(DIR_URL))
        .catch(err => console.error(err))
        .finally(() => setShowLoader(false))
    }
  }

  return (
    <div className={css.ChannelsLayout}>
      <div className={cn([css.ChannelsLayout__dirs, 'bd-right'])}>
        <Directories
          dirs={dirs}
          expandedKeys={expandedKeys}
          selectedDirectory={activeDirectory}
          isLoading={showLoader}
          withHeader
          draggable
          actions={[
            <AddDirectory key={'btn-add-directory'} selectedDirectory={activeDirectory} />,
            <ChangeDirectory
              key={'btn-edit-directory'}
              selectedDirectory={activeDirectory}
              onChanged={(name: string) => {
                dispatch(setSelectedDirectory(
                  activeDirectory ? { ...activeDirectory, title: name } : null
                ))
              }}
            />,
            <DeleteDirectory
              key={'btn-delete-directory'}
              selectedDirectory={activeDirectory}
              onDelete={() => dispatch(setSelectedDirectory(null))}
            />,
          ]}
          onExpand={(keys) => dispatch(setExpandedDirs(keys))}
          onSelect={dir => dispatch(setSelectedDirectory(dir))}
          onDrop={onDropHandler}
        />
      </div>

      <ChannelContent selectedDirectory={activeDirectory} />
    </div>
  )
}
