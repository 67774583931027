import React from 'react'
import type { MenuProps } from 'antd'
import { EditVar } from 'features/variables/edit-var'
import { DeleteVar } from 'features/variables/delete-var'
import type { VarItem, VarNames } from 'shared/types'

export const createVarsTableActions = (
  dataIndex: string,
  variables: VarItem[],
  varNameField: VarNames,
  onChange: (vars: VarItem[]) => Promise<any>,
): MenuProps['items'] => (
  [
    {
      key: '1',
      label: 'Привязать каналы',
    },
    {
      key: '2',
      label: (
        <EditVar
          name={dataIndex}
          variables={variables}
          varNameField={varNameField}
          onEdit={onChange}
        />
      ),
    },
    {
      key: '3',
      label: (
        <DeleteVar
          name={dataIndex}
          variables={variables}
          varNameField={varNameField}
          onEdit={onChange}
        />
      ),
      danger: true
    }
  ]
)
