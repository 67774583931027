import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import type { FC, Key, Dispatch } from 'react'
import { Button, Space } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { LinkOutlined } from '@ant-design/icons'
import { ModuleReducerAction, setIsChanged, setSettings } from 'layouts/module-layout'
import { AddRequest } from 'features/modbus/add-request'
import { DeleteRequest } from 'features/modbus/delete-request'
import type { Channel, ChannelsTableData } from 'entities/channel'
import { useChannels } from 'shared/hooks/use-channels'
import { EditableRow } from 'shared/ui/table/editable-row'
import { EditableCell } from 'shared/ui/table/editable-cell'
import { TableResponsive } from 'shared/ui/table/table-responsive'
import { updateTableData } from '../model/update-table-data'
import { COLUMNS } from '../config'
import type { ModbusRequest, ModbusRTUSettingsType, ModbusTCPSettingsType } from '../types'

type RequestsTableProps = {
  settings: ModbusRTUSettingsType | ModbusTCPSettingsType
  reducerDispatch: Dispatch<ModuleReducerAction>
  onBindingClick: ( data: Channel[] | undefined, record: any) => void
}

export const RequestsTable: FC<RequestsTableProps> = ({ settings, reducerDispatch, onBindingClick }) => {
  const [channelColumns, setChannelColumns] = useState<ColumnsType<ModbusRequest>>([])
  const [tableData, setTableData] = useState<ModbusRequest[]>([])
  const [channelsArr, setChannelsArr] = useState<string[] | null>(null)
  const [selectedRows, setSelectedRows] = useState<Key[]>([])
  const tableRef = useRef(null)
  const { data, isLoading } = useChannels(channelsArr)

  useLayoutEffect(() => {
    if (data) {
      const { requestsData } = updateTableData(settings.requests, tableData, data.channels)

      setTableData(requestsData)
    }
  }, [data])

  useEffect(() => {
    if (settings) {
      const { channelsColumns, requestsData } = updateTableData(settings.requests, tableData, data?.channels)

      setChannelColumns(channelsColumns)
      setTableData(requestsData)
      setChannelsArr(() => {
        const channels = settings.requests.reduce<string[]>((acc, curr) => (
          acc.concat(curr.channels)
        ), [])

        return channels.length
          ? Array.from(new Set(channels))
          : null
      })
    }
  }, [settings])

  const handleChange = (record: ModbusRequest, changedValue: Partial<ModbusRequest>) => {
    reducerDispatch(setSettings({
      ...settings,
      requests: settings.requests.map(r => (
        (r.id === record.id) ? { ...r, ...changedValue } : r
      ))
    }))
    reducerDispatch(setIsChanged(true))
  }

  return (
    <div
      ref={tableRef}
      className={'module-table'}
    >
      <TableResponsive
        columns={[...COLUMNS, ...channelColumns]}
        dataSource={tableData}
        parentRef={tableRef}
        loading={isLoading}
        titleComponents={[
          <AddRequest key={'AddRequest'} />,
          <DeleteRequest
            key={'DeleteRequest'}
            keys={selectedRows}
            isDisabled={!selectedRows.length}
            onDeleteEvent={() => setSelectedRows([])}
          />
        ]}
        components={{
          body: {
            row: (props: any) => <EditableRow handleChange={handleChange} {...props} />,
            cell: (props: any) => <EditableCell {...props} />,
          },
        }}
        rowSelection={{
          type: 'checkbox',
          columnWidth: 66,
          selectedRowKeys: selectedRows,
          onChange: (selectedRowKeys: Key[]) => {
            setSelectedRows(selectedRowKeys)
          },
          getCheckboxProps: (record: ChannelsTableData) => ({
            name: String(record.key),
          }),
          renderCell: (checked, record, index, originNode) => {
            return <div style={{ display: 'flex' }}>
              <Space>
                {originNode}
                <Button
                  type="primary"
                  icon={<LinkOutlined />}
                  size={'small'}
                  onClick={() => onBindingClick(data?.channels, record)}
                />
              </Space>
            </div>
          }
        }}
      />
    </div>
  )
}
