import React, { FC, useContext, useEffect, useRef } from 'react'
import { Col, Flex, Form, Input, Row } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { AddICMPHost } from 'features/icmp/add-host'
import { dispatchEvent } from 'shared/lib/custom-events'
import filterNums from 'shared/lib/filter-nums'
import { RequestsTable } from 'shared/ui/table/requests-table'
import { COLUMNS } from './config'
import type { ICMPSettingsType } from './types'
import css from './Icmp.module.scss'

export const ICMP: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<ICMPSettingsType>>(ModuleContext)
  const formRef = useRef(null)
  const [form] = Form.useForm()

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        period: settings.period,
        timeout_response: settings.timeout_response,
      })
    }
  }, [settings])

  return (
    <div className={css.Icmp}>
      <Flex vertical style={{ height: '100%' }}>
        <div className={css.Icmp_header}>
          <Form
            name={'icmp-form'}
            form={form}
            layout={'vertical'}
            ref={formRef}
            initialValues={{ period: 0 }}
            onKeyUp={e => {
              if (e.code === 'Enter' && isChanged) dispatchEvent('module:onPressEnter')
            }}
          >
            <Row gutter={[10, 0]} align={'bottom'}>
              <Col span={6}>
                <Form.Item
                  label="Период опроса (мсек.)"
                  name="period"
                >
                  <Input
                    onKeyPress={filterNums}
                    onChange={(e) => {
                      reducerDispatch(setSettings({
                        ...settings,
                        period: +e.target.value
                      }))
                      reducerDispatch(setIsChanged(true))
                    }}
                    onBlur={e => {
                      if (!e.target.value)
                        form.setFieldValue('period', 0)
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  label="Время ожидания ответа (сек.)"
                  name="timeout_response"
                >
                  <Input
                    onKeyPress={filterNums}
                    onChange={(e) => {
                      reducerDispatch(setSettings({
                        ...settings,
                        timeout_response: +e.target.value
                      }))
                      reducerDispatch(setIsChanged(true))
                    }}
                    onBlur={e => {
                      if (!e.target.value)
                        form.setFieldValue('timeout_response', 0)
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>

        <RequestsTable<ICMPSettingsType>
          columns={COLUMNS}
          settings={settings}
          reducerDispatch={reducerDispatch}
          isChanged={isChanged}
          itemsName={'remote_hosts'}
          itemPrefix={'remote-host-'}
          deleteText={'Удалить хост'}
          headerComponents={[<AddICMPHost key={'AddICMPHost'} />]}
        />
      </Flex>
    </div>
  )
}
